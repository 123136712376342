import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const CertificateEndpoint = `${endpoint}/bake-teach/certificate`;
const CertificateApi = {
  getList: async (params) =>
    await api
      .get(`${CertificateEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  getSummaryList: async (params) =>
    await api
      .get(`${CertificateEndpoint}/summary-list`, params)
      .then((data) => data?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${CertificateEndpoint}/create`, params)
      .then((result) => result?.data),
  getDetail: async (id: any) =>
    await api
      .get(`${CertificateEndpoint}/detail/${id}`)
      .then((result) => result?.data?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${CertificateEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${CertificateEndpoint}/delete/${id}`)
      .then((result) => result?.data),
};
export default CertificateApi;
