import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import {
  DATE_BACKEND_FORMAT,
  DATE_FORMAT,
  MAXIMUM_QUANTITY,
  TIME_FORMAT,
} from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import TableTera from '_common/dof/TableTera';
import { OPERATION_KEY } from '_common/dof/TableTera/constants';
import { useStores } from '_common/hooks';
import useConfirm from '_common/hooks/useConfirm';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  formatNumber,
  notification,
  PaginationProps,
  PlusCircleOutlined,
  TrashOutlined,
} from 'tera-dls';
import CourseScheduleApi from '../../api/Schedule';

interface IProps {
  mode?: 'create' | 'update';
  dataDetail?: any;
}

const Schedule = ({ mode = 'create', dataDetail }: IProps, ref) => {
  const confirm = useConfirm();
  const {
    course: { schedule, setSchedule, nextStep },
  } = useStores();

  const [data, setData] = useState<any>([]);
  const [params, setParams] = useState<any>({ page: 1, limit: 10 });
  const queryClient = useQueryClient();
  const isUpdateMode = mode == 'update';
  const actionRef = useRef(null);

  const {
    data: list,
    refetch: fetchData,
    isFetching,
  } = useQuery(
    ['get-course-schedule-list', dataDetail?.id, params],
    () =>
      CourseScheduleApi.getList({
        course_id: dataDetail?.id,
        ...params,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!dataDetail?.id,
    },
  );

  useEffect(() => {
    dataDetail?.id && fetchData();
  }, [dataDetail?.id]);

  const { mutate: mutateUpdate } = useMutation(
    (variables: any) => CourseScheduleApi.update(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-schedule-list']);
          queryClient.invalidateQueries(['get-course-summary-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const { mutate: mutateAdd } = useMutation(
    (variables: any) => CourseScheduleApi.create(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-schedule-list']);
          queryClient.invalidateQueries(['get-course-summary-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        nextStep: () => {
          if (!schedule || schedule?.length == 0) {
            notification.error({
              message: 'Vui lòng khai báo ít nhất 1 thời gian học',
            });
            return;
          }
          nextStep();
        },
      };
    },
    [schedule, nextStep, ref],
  );

  useEffect(() => {
    if (isUpdateMode && !!list?.data) {
      setData(
        list?.data?.map((i) => ({
          ...i,
          date: moment(i?.date),
          end_time: moment(i?.end_time, TIME_FORMAT),
          start_time: moment(i?.start_time, TIME_FORMAT),
        })),
      );
      return;
    }
    schedule?.length > 0 && setData(schedule);
  }, [schedule, list?.data, isUpdateMode]);

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => CourseScheduleApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-schedule-list']);
          queryClient.invalidateQueries(['get-course-summary-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (record): void => {
    confirm.warning({
      title: 'Xoá thời gian',
      content: (
        <>
          <p>Bạn có chắc muốn xóa thời gian</p>
          <p>này không?</p>
        </>
      ),
      onOk: () => {
        if (isUpdateMode) {
          actionRef.current?.deleteRow(record?.id, record?.index);
          return;
        }
        setData((prev) => {
          const data = prev?.filter((i) => i?.id !== record?.id);
          setSchedule(data);
          return data;
        });
      },
    });
  };

  const columns: any = [
    {
      title: 'Ngày',
      dataIndex: 'date',
      width: 200,
      type: 'date',
      editable: true,
      rules: [
        {
          required: messageValidate.emptySelect,
        },
      ],
      render: (val) => (
        <div className="py-1">{moment(val).format(DATE_FORMAT)}</div>
      ),
    },
    {
      title: 'Thời gian bắt đầu',
      dataIndex: 'start_time',
      width: 150,
      type: 'time-picker',
      editable: true,
      rules: [
        {
          required: messageValidate.emptySelect,
        },
        {
          validate: {
            range: (value, values) => {
              if (value == undefined || values?.end_time == undefined)
                return true;

              if (value?.isSameOrAfter(values?.end_time, 'minutes'))
                return 'Vui lòng chọn thời gian nhỏ hơn';
              const existedValue = data
                ?.filter((item) => !!item.end_time && !!item.start_time)
                ?.find((i) => {
                  if (i?.id === values?.id || !values?.date?.isSame(i?.date))
                    return false;
                  if (
                    value.isSameOrBefore(i.start_time, 'minutes') &&
                    values?.end_time?.isSameOrBefore(i.start_time, 'minutes')
                  )
                    return false;
                  if (
                    value?.isSameOrAfter(i?.end_time, 'minutes') &&
                    values?.end_time?.isSameOrAfter(i?.end_time, 'minutes')
                  )
                    return false;
                  return true;
                });
              return !!existedValue ? 'Thời gian đã tồn tại!' : true;
            },
          },
        },
      ],
      inputProps: {
        placeholder: 'Vui lòng chọn',
        format: 'HH:mm',
      },
      render: (val) => moment(val).format('HH:mm'),
    },
    {
      title: 'Thời gian kết thúc',
      dataIndex: 'end_time',
      width: 150,
      type: 'time-picker',
      editable: true,
      rules: [
        {
          required: messageValidate.emptySelect,
          validate: {
            range: (value, values) => {
              if (value == undefined || values?.start_time == undefined)
                return true;
              if (value.isSameOrBefore(values?.start_time, 'minutes'))
                return 'Vui lòng chọn thời gian lớn hơn';

              const existedValue = data
                ?.filter((item) => !!item.start_time && !!item.end_time)
                ?.find((i) => {
                  if (i?.id === values?.id || !values?.date?.isSame(i?.date))
                    return false;

                  if (
                    value?.isSameOrBefore(i.start_time, 'minutes') &&
                    values?.start_time?.isSameOrBefore(i.start_time, 'minutes')
                  )
                    return false;
                  if (
                    value?.isSameOrAfter(i.end_time, 'minutes') &&
                    values?.start_time?.isSameOrAfter(i.end_time, 'minutes')
                  )
                    return false;

                  return true;
                });
              return !!existedValue ? 'Thời gian đã tồn tại!' : true;
            },
          },
        },
      ],
      inputProps: {
        placeholder: 'Vui lòng chọn',
        format: 'HH:mm',
      },
      render: (val) => moment(val).format('HH:mm'),
    },
    {
      title: 'Số học viên đăng ký',
      dataIndex: 'count_invoices',
      width: 150,
      editable: true,
      inputProps: {
        disabled: true,
      },
      render: (val) => formatNumber(val ?? 0),
    },
    {
      title: 'Giới hạn học viên',
      dataIndex: 'limit_student',
      width: 150,
      editable: true,
      type: 'int',
      rules: [
        {
          validate: {
            min: (val) => {
              if (val != undefined) {
                if (val < 1) return 'Vui lòng nhập giá trị lớn hơn 0';
              }
              return true;
            },
          },
        },
      ],
      inputProps: {
        max: MAXIMUM_QUANTITY,
      },
      render: (val) => val && formatNumber(val),
    },
    {
      title: (
        <div className="flex justify-center">
          <PlusCircleOutlined
            className="size-5 cursor-pointer text-green-500"
            onClick={() => actionRef.current?.addRow()}
          />
        </div>
      ),
      dataIndex: OPERATION_KEY,
      width: 80,
      fixed: 'right',
    },
  ];

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    setParams({
      page,
      limit: pageSize,
    });
  };

  return (
    <>
      <div className="space-y-2.5">
        {!isUpdateMode && (
          <h4 className="text-blue-500 font-medium mb-5 ">Thời gian học</h4>
        )}

        <TableTera
          rowKey={'id'}
          data={data}
          actionRef={actionRef}
          mode="editable-row"
          loading={isUpdateMode && isFetching}
          recordCreatorProps={{
            record: () => ({
              count_invoices: 0,
            }),
          }}
          editable={{
            onValueFormChange(value, form) {
              const { name } = value ?? {};
              const start_time = form.getValues('start_time');
              const end_time = form.getValues('end_time');

              if (name === 'start_time' || name === 'end_time') {
                form.trigger('start_time');
                form.trigger('end_time');
              }

              if (
                name === 'date' &&
                end_time != undefined &&
                start_time != undefined
              ) {
                form.trigger('start_time');
                form.trigger('end_time');
              }
            },
            ...(!isUpdateMode && {
              onValuesChange(_, recordList) {
                setSchedule(recordList);
              },
            }),
            ...(isUpdateMode && {
              onUpdate(record) {
                mutateUpdate({
                  id: record?.id,
                  params: {
                    date: record?.date.format(DATE_BACKEND_FORMAT),
                    start_time: record?.start_time?.format(TIME_FORMAT),
                    end_time: record?.end_time?.format(TIME_FORMAT),
                    limit_student: record?.limit_student ?? null,
                  },
                });
              },
              onAdd(record) {
                mutateAdd({
                  params: {
                    course_id: dataDetail?.id,
                    date: record?.date.format(DATE_BACKEND_FORMAT),
                    start_time: record?.start_time?.format(TIME_FORMAT),
                    end_time: record?.end_time?.format(TIME_FORMAT),
                    limit_student: record?.limit_student ?? null,
                  },
                });
              },
              onDelete(record) {
                mutateDelete({ id: record?.id });
              },
            }),

            buttonDeleteIndicator(record, editingKey) {
              if (record?.index == 0) {
                return <></>;
              }
              return (
                <TrashOutlined
                  className={` ${
                    !!editingKey
                      ? 'text-[#00000040] cursor-not-allowed'
                      : 'text-red-600 cursor-pointer'
                  }`}
                  width={'1rem'}
                  height={'1rem'}
                  onClick={() => {
                    handleDelete(record);
                  }}
                />
              );
            },
          }}
          pagination={{
            ...(isUpdateMode && {
              onChange: handleChangePage,
              total: list?.total || 0,
              current: list?.current_page,
              pageSize: list?.per_page || 10,
              to: list?.to,
              from: list?.from || 10,
            }),
          }}
          columns={columns}
        />
      </div>
    </>
  );
};

export default observer(forwardRef(Schedule));
