import { useQuery } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import moment from 'moment';
import { COURSE_METHOD } from 'pages/CourseManagement/Course/constants';
import { useEffect } from 'react';
import {
  Button,
  Description,
  formatCurrency,
  Modal,
  Spin,
  Tag,
} from 'tera-dls';
import CertificateApi from '../../api';
import {
  PROMOTION_SUGGEST_STATUS,
  PROMOTION_SUGGEST_STATUS_COLOR,
} from '../../constants';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
}

const CertificateDetail = (props: IProps) => {
  const { open, value: id, onClose } = props;

  const {
    data: detail,
    refetch: fetchData,
    isLoading,
  } = useQuery(
    ['get-certificate-detail', id],
    () => CertificateApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );

  useEffect(() => {
    id && fetchData();
  }, [id]);

  return (
    <>
      <Modal
        open={open}
        title={'Chi tiết đề xuất khuyến mãi'}
        closeIcon={false}
        width={500}
        maskClosable={false}
        footer={<Button onClick={onClose}>Đóng</Button>}
      >
        <Spin spinning={isLoading}>
          <Description label="Giảng viên">
            <HoverQuickView name={detail?.lecturer?.full_name}>
              <span className="line-clamp-1 text-blue-600">
                {detail?.lecturer?.full_name}
              </span>
            </HoverQuickView>
          </Description>
          <Description label="Phân loại khóa học">
            {COURSE_METHOD[detail?.type]}
          </Description>
          <Description label="Khóa học">
            <span className="line-clamp-1 text-blue-600">
              {detail?.course?.name}
            </span>
          </Description>
          <Description label="Số tiền gốc">
            {formatCurrency(detail?.course?.price)}
          </Description>
          <Description label="Số tiền đề chứng chỉ">
            {formatCurrency(detail?.price)}
          </Description>
          <Description label="Trạng thái">
            {detail?.status && (
              <Tag color={PROMOTION_SUGGEST_STATUS_COLOR[detail?.status]}>
                {PROMOTION_SUGGEST_STATUS[detail?.status]}
              </Tag>
            )}
          </Description>
          <Description label="Người tạo">
            <HoverQuickView name={detail?.user_created?.full_name}>
              <span className="line-clamp-1 text-blue-600">
                {detail?.user_created?.full_name}
              </span>
            </HoverQuickView>
          </Description>

          <Description label="Ngày tạo">
            {moment(detail?.created_at).format(DATE_TIME_FORMAT)}
          </Description>
          {detail?.user_updated && (
            <>
              <Description label="Người cập nhật">
                <HoverQuickView name={detail?.user_updated?.full_name}>
                  <span className="line-clamp-1 text-blue-600">
                    {detail?.user_updated?.full_name}
                  </span>
                </HoverQuickView>
              </Description>

              <Description label="Ngày cập nhật">
                {moment(detail?.updated_at).format(DATE_TIME_FORMAT)}
              </Description>
            </>
          )}
        </Spin>
      </Modal>
    </>
  );
};

export default CertificateDetail;
