import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageValidate } from '_common/constants/message';
import Image from '_common/dof/Control/Image';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectCourse from '_common/dof/Select/SelectCourse';
import SelectUser from '_common/dof/Select/SelectUser';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import CourseApi from 'pages/CourseManagement/Course/api';
import {
  COURSE_METHOD,
  COURSE_TYPE,
} from 'pages/CourseManagement/Course/constants';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  formatDate,
  FormItem,
  Input,
  Modal,
  notification,
  Spin,
} from 'tera-dls';
import CertificateApi from '../../api';
import UserApi from 'pages/UserManagement/User/api';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
  course_id?: string | number;
}

const CertificateForm = (props: IProps) => {
  const { open, value: id, onClose, course_id: initialCourseId } = props;
  const form = useForm({ mode: 'onChange' });
  const {
    handleSubmit,
    formState: { isDirty },
  } = form;

  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const course_id = form.watch('course_id');
  const user_id = form.watch('user_id');

  const handleCancel = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>Bạn có chắc muốn thoát bản ghi này không?</p>
            <p>Sau khi thoát dữ liệu sẽ không được lưu.</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
      return;
    }
    onClose();
  };

  const {
    data: detail,
    refetch: fetchData,
    isFetching,
  } = useQuery(
    ['get-certificate-detail', id],
    () => CertificateApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );

  const { data: detailCourse, refetch } = useQuery(
    ['get-course-detail', course_id, initialCourseId],
    () => CourseApi.getDetail(course_id ?? initialCourseId),
    {
      enabled: !!course_id || !!initialCourseId,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: detailUser } = useQuery(
    ['get-user-detail', user_id],
    () => UserApi.getDetail(user_id),
    {
      enabled: !!user_id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    initialCourseId && refetch();
  }, [initialCourseId]);

  useEffect(() => {
    if (!!detailCourse && !!initialCourseId) {
      form.setValue('course_id', initialCourseId);
    }
  }, [detailCourse, initialCourseId]);

  useEffect(() => {
    if (detail) {
      const data = _.pick(detail, ['course_id', 'user_id', 'avatar']);

      form.reset(data);
    }
  }, [detail]);

  useEffect(() => {
    id && fetchData();
  }, [id]);

  const { mutate: mutateAction, isLoading } = useMutation(
    (variables: any) =>
      id ? CertificateApi.update(variables) : CertificateApi.create(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-certificate-list']);
          notification.success({
            message: res?.msg,
          });
          onClose();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values) => {
    mutateAction({
      params: {
        ...values,
      },
      ...(id && { id }),
    });
  };

  return (
    <>
      <Modal
        open={open}
        title={id ? 'Sửa chứng chỉ' : 'Thêm chứng chỉ'}
        closeIcon={false}
        okText="Đồng ý"
        cancelText="Hủy"
        width={500}
        maskClosable={false}
        onOk={handleSubmit(handleSubmitForm)}
        onCancel={handleCancel}
        okButtonProps={{
          loading: isLoading,
          disabled: isLoading,
        }}
      >
        <Spin spinning={!!id && isFetching}>
          <FormTera form={form} isLoading={false}>
            <FormTeraItem
              label="Họ và tên"
              name="user_id"
              rules={[
                {
                  required: messageValidate.emptySelect,
                },
              ]}
            >
              <SelectUser />
            </FormTeraItem>
            <FormItem label="Ngày sinh" isRequired={false}>
              <Input disabled value={formatDate(detailUser?.birthday)} />
            </FormItem>
            <FormTeraItem
              label="Chương trình đào tạo (Tên khoá học)"
              name="course_id"
              rules={[
                {
                  required: messageValidate.emptySelect,
                },
              ]}
            >
              <SelectCourse />
            </FormTeraItem>
            <FormItem label="Loại khoá học" isRequired={false}>
              <Input value={COURSE_TYPE[detailCourse?.type]} disabled />
            </FormItem>
            <FormItem label="Phân loại khóa học" isRequired={false}>
              <Input
                value={COURSE_METHOD[detailCourse?.classification]}
                disabled
              />
            </FormItem>
            {/* <FormItem label="Thời gian học" isRequired={false}>
              <Input disabled />
            </FormItem> */}
            <FormTeraItem label="Ảnh đại diện" name="avatar">
              <Image folder="certificate" object_key="certificate" />
            </FormTeraItem>
          </FormTera>
        </Spin>
      </Modal>
    </>
  );
};

export default CertificateForm;
