import ImageBackground from '_common/component/ImageBackground';
import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import { useTeraFormItem } from '_common/dof/FormTera/TeraItemContext';
import { Controller } from 'react-hook-form';

const Image = ({ folder, object_key }) => {
  const { form } = useTeraForm();
  const { item, rules } = useTeraFormItem();
  const { control } = form;

  return (
    <Controller
      {...item}
      control={control}
      rules={rules}
      render={({ field }) => {
        return (
          <ImageBackground
            value={{ url: field?.value }}
            onChange={(val) => {
              field?.onChange?.(val ? val?.url : null);
            }}
            isShowBtnDelete
            imageClassName="w-[100px] h-[100px] object-cover"
            object_key={object_key}
            folder={folder}
            error={!!form.formState.errors?.[item.name]}
          />
        );
      }}
    />
  );
};

export default Image;
