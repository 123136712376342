import { observer } from 'mobx-react-lite';
import { BrowserRouter } from 'react-router-dom';

import { Routers } from './routers';
import { onMessageListener, requestForToken } from './firebase';
import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useStores } from '_common/hooks';
import { AuthApi } from 'states/api';
import { notification } from 'tera-dls';
import NotificationApi from 'states/api/notification';

const basename = document.querySelector('base')?.getAttribute('href') ?? '/';

const Root = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore,
    commonStore: { setBusiness },
  } = useStores();
  useEffect(() => {
    const handleNotification = (payload) => {
      queryClient.invalidateQueries(['get-summary-notification-list']);
      notification.open({
        message: payload?.data?.title,
        description: payload?.data?.content,
      });
    };

    const unsubscribe = onMessageListener(handleNotification);

    return () => {
      unsubscribe();
    };
  }, []);

  useQuery(['get_profile'], AuthApi.getProfile, {
    staleTime: 300000,
    enabled: !!authStore.token,
    onSuccess: (res) => {
      setBusiness({
        id: res?.data?.business?.id,
        name: res?.data?.business?.name,
        ownerName: res?.data?.business?.owner_name,
      });

      const permissions = res?.data?.role?.role_has_permission?.map(
        (item) => item?.page_control?.permission_key,
      );

      authStore.updatePermissions(permissions);
      authStore.updateUser({ user: res?.data });
    },
  });

  const { mutate: mutationReadNotify } = useMutation(async () => {
    const token = await requestForToken();

    const params = {
      user_id: authStore?.user?.id,
      token,
    };
    return NotificationApi.addToken({ params });
  });

  useEffect(() => {
    mutationReadNotify();
  }, []);

  return (
    <BrowserRouter basename={basename}>
      <Routers />
    </BrowserRouter>
  );
});

export default Root;
