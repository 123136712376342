import Filter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import RangePicker from '_common/dof/Control/RangePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TYPE_REFUND } from '../../constants';

interface RefundFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function RefundFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: RefundFilterProps) {
  const form = useForm({ mode: 'onChange' });

  const handleSubmitForm = (values) => {
    const data = {
      ...values,
      start_created: values?.date?.[0]?.format(DATE_BACKEND_FORMAT),
      end_created: values?.date?.[1]?.format(DATE_BACKEND_FORMAT),
    };
    onFilter(data);
    onClose();
  };

  const handleReset = () => {
    const values = {
      classification: null,
      date: null,
      start_created: null,
      end_created: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const values = _.pick(initialValue, ['classification', 'date']);
    const data = {
      ...values,
      ...(initialValue?.start_created &&
        initialValue?.end_created && {
          date: [
            moment(initialValue?.start_created),
            moment(initialValue?.end_created),
          ],
        }),
    };
    form.reset(data);
  }, [initialValue]);

  const optionsTypeRefund = Object.entries(TYPE_REFUND).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem label="Thời gian" name="date">
          <RangePicker />
        </FormTeraItem>
        <FormTeraItem label="Loại hóa đơn" name="classification">
          <Select options={optionsTypeRefund} allowClear />
        </FormTeraItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default RefundFilter;
