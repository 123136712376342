import { formatCurrency, Spin } from 'tera-dls';
import Header from '../Header';
import ModalChart from './ModalChart';
import { useState } from 'react';
import classNames from 'classnames';
import DefaultImage from '_common/component/DefaultImage';
import moment from 'moment';
import { DATE_FORMAT } from '_common/constants/common';
import { useQuery } from '@tanstack/react-query';
import DashboardApi from '../api';
import NoData from '_common/component/NoData';

function TopLecturer(props) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { type = 'small', wrapperClassName, onClose } = props;
  const isSmallType = type === 'small';

  const startOfMonth = moment().startOf('month').format(DATE_FORMAT);
  const endOfMonth = moment().endOf('month').format(DATE_FORMAT);

  const { data, isLoading } = useQuery(
    ['get-top-lecturer-list'],
    () => DashboardApi.getTopLecturer(),
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );
  return (
    <Spin spinning={isLoading}>
      <Header
        isSmallType={isSmallType}
        title="Top 10 giảng viên"
        isRefetch={false}
        isSetting={false}
        onZoomIn={onClose}
        onZoomOut={() => setOpenModal(true)}
      />
      <span className="text-[10px] text-[#6B7280]">
        {startOfMonth} - {endOfMonth}
      </span>
      <div
        className={classNames(
          'mt-2.5 overflow-auto pr-2.5 max-h-[300px]',
          wrapperClassName,
        )}
      >
        {data?.length > 0 ? (
          <>
            {data?.map((item, index) => (
              <div
                key={index}
                className="flex justify-between items-center gap-x-2.5 pt-2.5 pb-2.5 [&:not(:last-child)]:border-b"
              >
                <div className="flex gap-x-2.5">
                  <span className="text-[10px] min-w-[20px]">{index + 1}.</span>{' '}
                  <DefaultImage
                    src={item?.avatar}
                    alt={item?.full_name}
                    className="w-[35px] h-[35px] rounded-full"
                  />
                  <span className="text-blue-600 font-medium break-word line-clamp-2 ">
                    {item?.full_name}
                  </span>
                </div>
                <p className="text-green-500 font-medium shrink-0 min-w-max">
                  {formatCurrency(item?.total)}
                </p>
              </div>
            ))}
          </>
        ) : (
          <NoData />
        )}
      </div>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
        />
      )}
    </Spin>
  );
}

export default TopLecturer;
