export const PROMOTION_SUGGEST_STATUS = {
  pending: 'Chờ chấp nhận',
  received: 'Đã chấp nhận',
  reject: 'Từ chối',
};

export const PROMOTION_SUGGEST_STATUS_COLOR = {
  pending: 'yellow03',
  received: 'green03',
  reject: 'red03',
};
