import { useQuery } from '@tanstack/react-query';
import TableTera from '_common/dof/TableTera';
import { IPagination } from '_common/interface';
import { useState } from 'react';
import { formatDate, formatNumber, PaginationProps } from 'tera-dls';
import UserReportApi from '../../api';
import Searching from '../Searching';
import { USER_TYPE } from 'pages/UserManagement/User/constants';

const TableUserReport = () => {
  const [search, setSearch] = useState<string>(null);
  const [pagination, setPagination] = useState<IPagination>({
    limit: 10,
    page: 1,
  });
  const { data, isFetching } = useQuery(
    ['get-user-report-list', pagination, search],
    () => {
      const params = {
        ...pagination,
        keyword: search,
      };
      return UserReportApi.getList({ params });
    },
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const columns = [
    {
      title: 'Mã người dùng',
      dataIndex: 'code',
    },

    {
      title: 'Họ và tên',
      dataIndex: 'full_name',
    },
    {
      title: 'Loại người dùng',
      dataIndex: 'type',
      render: (val) => USER_TYPE[val],
    },
    {
      title: 'Ngày lập tài khoản',
      dataIndex: 'created_at',
      render: (val) => formatDate(val),
    },
    {
      title: 'Số lượng đơn hàng',
      dataIndex: 'total_invoice',
      render: (val) => formatNumber(val),
    },
    {
      title: 'Số lượng khóa học',
      dataIndex: 'total_count_course_lecturer',
      render: (val) => formatNumber(val),
    },
  ];

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(data?.limit || 10);

    setPagination({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  return (
    <div className="bg-white rounded">
      <div className="flex justify-end p-2.5">
        <Searching
          onSearch={(value) => {
            setSearch(value?.keyword);
            setPagination((pre) => ({ ...pre, page: 1 }));
          }}
        />
      </div>
      <TableTera
        loading={isFetching}
        wrapperClassName="shadow-none"
        rowKey={'id'}
        data={data?.data || []}
        className="center-table"
        pagination={{
          onChange: handleChangePage,
          total: data?.total || 0,
          current: data?.current_page,
          pageSize: data?.per_page || 10,
          to: data?.to,
          from: data?.from || 10,
        }}
        columns={columns}
      />
    </div>
  );
};

export default TableUserReport;
