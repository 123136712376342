import CardFormV2 from '_common/component/CardForm/CardFormV2';
import CopyClipboardText from '_common/component/CopyClipboardText';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '_common/constants/common';
import { renderDescription } from '_common/utils/utils';
import moment from 'moment';
import QuestionIcon from 'styles/images/Icons/QuestionIcon';
import customTwMerge from 'tailwind-merge.config';
import {
  Col,
  formatCurrency,
  Image,
  PlayCircleOutlined,
  Row,
  Tag,
} from 'tera-dls';
import {
  COURSE_METHOD,
  COURSE_METHOD_COLOR,
  COURSE_STATUS,
  COURSE_STATUS_COLOR,
  COURSE_SYSTEM_STATUS,
  COURSE_SYSTEM_STATUS_COLOR,
  COURSE_TYPE,
} from '../../constants';
import HoverQuickView from '_common/component/HoverQuickView';
import VideoBackground from '_common/component/VideoBackground';
const borderBox =
  'shadow-xsm border-[1px] border-blue-400 rounded-[6px] relative';
const iconBorder =
  'bg-blue-600 p-2 flex justify-center items-center w-10 h-10 rounded-full absolute top-[-16px] left-[16px]';

const Overview = ({ dataDetail }) => {
  const data1 = [
    {
      key: '1',
      title: 'Tên khóa học',
      value: dataDetail?.name,
    },

    {
      key: '4',
      title: 'Danh mục khoá học',
      value: dataDetail?.category?.name,
    },

    {
      key: '6',
      title: 'Loại khoá học',
      value: COURSE_TYPE[dataDetail?.type],
    },
    {
      key: '8',
      title: 'Phân loại khoá học',
      value: (
        <span className={`${COURSE_METHOD_COLOR[dataDetail?.classification]}`}>
          {COURSE_METHOD[dataDetail?.classification]}
        </span>
      ),
    },

    {
      key: '10',
      title: 'Giá khoá học',
      value: formatCurrency(dataDetail?.price ?? 0),
    },

    {
      key: '13',
      title: 'Giá khuyến mãi',
      value: dataDetail?.discount_price
        ? formatCurrency(dataDetail?.discount_price)
        : '',
    },
    {
      key: '14',
      title: 'Thời gian khuyến mãi',
      value: dataDetail?.discount_time?.[0] && (
        <span>
          {moment(dataDetail?.discount_time?.[0]).format(DATE_FORMAT)} -{' '}
          {moment(dataDetail?.discount_time?.[1]).format(DATE_FORMAT)}
        </span>
      ),
    },
  ];

  const data3 = [
    // {
    //   key: '1',
    //   title: 'Giới hạn học viên',
    //   value: dataDetail?.limit_student,
    // },
    // {
    //   key: '3',
    //   title: 'Học viên đã đăng ký',
    //   value: dataDetail?.students_registered_count ?? 0,
    // },
    {
      key: '2',
      title: 'Link khoá học',
      value: dataDetail?.link_url && (
        <div className="flex gap-2 items">
          <span className="text-blue-500 ">{dataDetail?.link_url}</span>
          <CopyClipboardText
            valueCopy={dataDetail?.link_url}
            className="text-green-500"
          />
        </div>
      ),
    },
    ...(dataDetail?.classification == 'online'
      ? [
          {
            key: '5',
            title: 'Mật khẩu khoá học',
            value: dataDetail?.password && (
              <div className="flex gap-2 items">
                <span className="text-blue-500">{dataDetail?.password}</span>
                <CopyClipboardText
                  valueCopy={dataDetail?.password}
                  className="text-green-500"
                />
              </div>
            ),
          },
        ]
      : [
          {
            key: '200',
          },
        ]),

    {
      key: '7',
      title: 'Giảng viên',
      value: (
        <HoverQuickView
          name={dataDetail?.lecturer?.full_name}
          avatarUrl={dataDetail?.lecturer?.avatar}
          email={dataDetail?.lecturer?.email}
          phone={dataDetail?.lecturer?.phone}
        >
          <span className="line-clamp-1 text-blue-600">
            {dataDetail?.lecturer?.full_name}
          </span>
        </HoverQuickView>
      ),
    },
    {
      key: '9',
      title: 'Trạng thái khoá học',
      value: (
        <Tag color={COURSE_STATUS_COLOR[dataDetail?.is_active]}>
          {COURSE_STATUS[dataDetail?.is_active]}
        </Tag>
      ),
    },
    {
      key: '11',
      title: 'Trạng thái hệ thống',
      value: (
        <Tag color={COURSE_SYSTEM_STATUS_COLOR[dataDetail?.status]}>
          {COURSE_SYSTEM_STATUS[dataDetail?.status]}
        </Tag>
      ),
    },
  ];

  const data2 = [
    {
      key: '2',
      title: 'Số lượng video',
      value: dataDetail?.count_videos ?? 0,
    },
    {
      key: '3',
      title: 'Số lượng bài tập',
      value: dataDetail?.assignments_count,
    },
    {
      key: '4',
      title: 'Số lượng học viên',
      value: dataDetail?.students_count ?? 0,
    },

    {
      key: '5',
      title: 'Số lượng đánh giá hiện tại',
      value: dataDetail?.ratings_count,
    },
    {
      key: '6',
      title: 'Doanh thu',
      value: 'chưa có',
    },
  ];

  const linkVideo =
    dataDetail?.classification == 'online'
      ? !!dataDetail?.link_url_direct
      : !!dataDetail?.link_url;

  return (
    <Row className="grid-cols-6 gap-[30px]">
      <Col className="col-span-1">
        <div
          className={customTwMerge(
            'col-span-1 flex flex-col p-[16px] divide-y-[1px]',
            borderBox,
          )}
        >
          <div className="flex flex-col justify-center items-center gap-2.5 pb-2.5">
            <Image
              src={dataDetail?.image_url}
              containerClassName="!size-[150px] rounded-[10px] overflow-hidden"
              imageClassName=" object-cover"
              borderRadius={10}
            />
            <div className="space-y-[5px] text-center break-word">
              <p className="text-[16px] leading-[20px] tracking-[1px]">
                {dataDetail?.name}
              </p>
              <p className="text-[#0E9F6E]">{dataDetail?.code}</p>
            </div>
          </div>
          <div className="pt-2.5 flex flex-col gap-2.5">
            <div className="text-[#6B7280]">Người tạo</div>
            <div className="text-blue-600">
              {dataDetail?.user_created?.full_name}
            </div>
            <div className="text-[#6B7280]">Ngày tạo</div>
            <div className="text-[#1F2937]">
              {dataDetail?.created_at &&
                moment(dataDetail?.created_at).format(DATE_TIME_FORMAT)}
            </div>
            {dataDetail?.user_updated && (
              <>
                <div className="text-[#6B7280]">Người cập nhật</div>
                <div className="text-blue-600">
                  {dataDetail?.user_updated?.full_name}
                </div>
                <div className="text-[#6B7280]">Ngày cập nhật</div>
                <div className="text-[#1F2937]">
                  {moment(dataDetail?.updated_at).format(DATE_TIME_FORMAT)}
                </div>
              </>
            )}
          </div>
        </div>
      </Col>
      <Col className="col-span-5 flex flex-col gap-[30px]">
        <div
          className={customTwMerge(
            'flex flex-col bg-white p-5 gap-5',
            borderBox,
          )}
        >
          <div className={customTwMerge('', iconBorder)}>
            <QuestionIcon className="w-full h-full text-white" />
          </div>
          <CardFormV2 title="Thông tin chung">
            <Row className="grid grid-cols-2">
              <Col className="flex flex-col gap-2">
                {renderDescription(data1)}
              </Col>
              <Col className="flex flex-col gap-2">
                {renderDescription(data3)}
              </Col>
            </Row>
          </CardFormV2>
        </div>
        <div
          className={customTwMerge(
            'flex flex-col bg-white p-5 gap-5',
            borderBox,
          )}
        >
          <div className={customTwMerge('', iconBorder)}>
            <PlayCircleOutlined className="w-full h-full text-white" />
          </div>
          <CardFormV2 title="Thông tin khóa học">
            <Row className="grid grid-cols-1 mb-2.5">
              {renderDescription(
                data2,
                'xmd:!max-w-[200px] xmd:!min-w-[200px]',
              )}
            </Row>
            {linkVideo && (
              <VideoBackground
                isView
                size="large"
                value={{
                  url:
                    dataDetail?.classification == 'online'
                      ? dataDetail?.link_url_direct
                      : dataDetail?.link_url,
                }}
                type={
                  dataDetail?.classification == 'online'
                    ? dataDetail?.link_url_direct_type
                    : dataDetail?.link_url_type
                }
              />
            )}
          </CardFormV2>
        </div>
      </Col>
    </Row>
  );
};

export default Overview;
