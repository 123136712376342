import { useMutation, useQuery } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { MAXIMUM_CURRENCY } from '_common/constants/common';
import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import TableTera from '_common/dof/TableTera';
import { ITableRowActionRef } from '_common/dof/TableTera/_interfaces';
import { OPERATION_KEY } from '_common/dof/TableTera/constants';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Button, formatCurrency, notification, Row } from 'tera-dls';
import CertificateConfigApi from '../../api/Certificate';

const CertificateConfig = () => {
  const actionRef = useRef<ITableRowActionRef>(null);
  const form = useForm();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['get-certificate-config-list'],
    queryFn: () => CertificateConfigApi.getList(),
    staleTime: 30000,
    cacheTime: 30000,
  });

  const { mutate: mutateDelete } = useMutation(
    (id: any) => CertificateConfigApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          refetch();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const { mutate: mutateCreate } = useMutation(
    (params: any) =>
      CertificateConfigApi.create({
        params,
      }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });

          form.reset();
          form.setValue('type_print', '');
          refetch();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const { mutate: mutateUpdate, isLoading: isSaving } = useMutation(
    (variables: any) =>
      CertificateConfigApi.update({
        id: variables?.id,
        params: variables?.params,
      }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          refetch();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );
  const isDisable = isSaving || isLoading;

  const columns = [
    {
      title: 'Kiểu in',
      dataIndex: 'type_print',
      width: 500,
      editable: true,
      rules: [{ required: 'Vui lòng nhập' }],
      inputProps: {
        autoFocus: true,
        maxLength: 255,
      },
    },
    {
      title: 'Chi phí',
      dataIndex: 'expense',
      width: 500,
      editable: true,
      type: 'int',
      inputProps: {
        min: 0,
        formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      },
      render: (val) => formatCurrency(val),
    },
    {
      dataIndex: OPERATION_KEY,
      width: 60,
    },
  ];

  const handleDelete = async (record) => {
    mutateDelete(record.id);
  };

  const handleAdd = (values) => {
    mutateCreate(values);
  };

  const handleUpdate = async (record) => {
    mutateUpdate({
      id: record?.id,
      params: {
        type_print: record?.type_print,
        expense: record?.expense,
      },
    });
  };

  return (
    <CardFormV2 title="Chi phí chứng chỉ khóa học" className="">
      <FormTera form={form} onSubmit={form.handleSubmit(handleAdd)}>
        <div className="flex items-end gap-x-2.5 p-2.5 rounded border mb-2.5">
          <Row className="grid-cols-2 gap-x-2.5 flex-1 ">
            <FormTeraItem
              name="type_print"
              label="Kiểu in"
              className="mb-0"
              rules={[{ required: ' ' }]}
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem name="expense" label="Chi phí" className="mb-0">
              <InputNumber
                min={0}
                max={MAXIMUM_CURRENCY}
                suffix={<span className="mr-5">đ</span>}
              />
            </FormTeraItem>
          </Row>
          <Button>+ Thêm mới</Button>
        </div>
      </FormTera>
      <TableTera
        loadingIndicator={{
          loading: isLoading,
        }}
        recordCreatorProps={{
          record: () => ({ amount: 0 }),
        }}
        scroll={{ y: 360 }}
        data={data || []}
        columns={columns}
        rowKey={'id'}
        mode="editable-row"
        actionRef={actionRef}
        editable={{
          saveOnClickOut: true,
          onValuesChange(_, recordList) {
            console.log(recordList);
          },
          onAdd: handleAdd,
          onDelete: handleDelete,
          onUpdate: handleUpdate,
          isDisabled: isDisable,
        }}
      />
    </CardFormV2>
  );
};

export default CertificateConfig;
