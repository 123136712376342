import { useMutation, useQuery } from '@tanstack/react-query';
import ButtonCommon from '_common/component/Button';
import DetectMedia from '_common/component/DetectMedia';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useStores } from '_common/hooks';
import { renderRating } from '_common/utils/utils';
import { observer } from 'mobx-react-lite';
import EvaluateApi from 'pages/CourseManagement/Course/api/Evaluate';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Collapse,
  formatDate,
  Image,
  Modal,
  notification,
  PaperAirplaneOutlined,
  Spin,
} from 'tera-dls';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
}

const StudentEvaluate = (props: IProps) => {
  const { open, value: id, onClose } = props;
  const [activeKeys, setActiveKeys] = useState<any>();
  const form = useForm();
  const {
    authStore: { user },
  } = useStores();
  const {
    data: detail,
    refetch: fetchData,
    isLoading,
  } = useQuery(
    ['get-evaluate-detail', id],
    () => EvaluateApi.getDetail({ id }),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );

  console.log('detail', detail);

  const { mutate: replyMutation } = useMutation(
    (params: any) => EvaluateApi.create({ params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          form.reset({
            content: '',
          });
          notification.success({ message: res?.msg });
          fetchData();
        }
      },

      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  useEffect(() => {
    id && fetchData();
  }, [id]);

  const handleSubmitForm = (values) => {
    const params = {
      parent_id: detail?.id,
      user_id: user?.id,
      object_id: detail?.object_id,
      object_type: detail?.object_type,
      content: values?.content,
      user_name: user?.full_name,
    };

    replyMutation(params);
  };

  const listCollapse = useCallback(
    () => [
      {
        key: 1,
        label: (
          <div className="!text-gray-400 text-xs font-normal">
            Trả lời đánh giá
          </div>
        ),
        children: (
          <FormTera
            form={form}
            className="flex flex-col gap-[5px]"
            onSubmit={form.handleSubmit(handleSubmitForm)}
          >
            <FormTeraItem
              name="content"
              rules={[
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <TextArea
                rows={8}
                className="bg-gray-100 border-none resize-none text-gray-700"
                placeholder="Hãy chia sẽ nhận xét cho học viên này bạn nhé!"
              />
            </FormTeraItem>
            <div className="flex justify-end">
              <ButtonCommon
                htmlType="submit"
                type="success"
                className="page-header-btn bg-green-500 font-light"
                icon={<PaperAirplaneOutlined className="w-4" />}
              >
                Gửi
              </ButtonCommon>
            </div>
          </FormTera>
        ),
      },
    ],
    [detail, user],
  );

  return (
    <>
      <Modal
        open={open}
        title={'Chi tiết đánh giá khóa học'}
        closeIcon={false}
        width={600}
        onCancel={onClose}
        footer={
          <Button onClick={onClose} className="px-5">
            Đóng
          </Button>
        }
      >
        <Spin spinning={isLoading}>
          <div className="rounded-[10px] border border-blue-500 p-2.5">
            <div className="space-y-2.5">
              <div className="flex gap-2.5 items-center">
                <Image
                  src={detail?.user?.avatar}
                  containerClassName="border !size-[40px] rounded-full overflow-hidden"
                  imageClassName="object-cover"
                />
                <div className="space-y-2">
                  <span className="line-clamp-1 text-blue-600 font-bold">
                    {detail?.user?.full_name}
                  </span>
                  <div className="flex gap-2.5 items-center">
                    {renderRating(detail?.star_count)}
                    <span className="size-[3px] bg-gray-700 rounded-full" />
                    <div className="leading-[17px] text-[#374151]">
                      {formatDate(detail?.created_at, DATE_TIME_FORMAT)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-2.5">
                <p className="break-word text-xs leading-[20px] text-gray-700">
                  {detail?.content}
                </p>
                <div className="flex gap-2.5 items-center">
                  {detail?.attachments?.map((file) => (
                    <DetectMedia
                      src={file?.url}
                      type={file?.type_file}
                      className="w-[80px] h-[80px] rounded overflow-hidden"
                    />
                  ))}
                </div>
              </div>
              <div className="flex flex-col ml-5 border-l-2 pl-2.5">
                {detail?.children?.map((reply) => (
                  <div className="flex flex-col gap-y-1 hover:bg-gray-100 p-1">
                    <div className="flex items-center gap-x-2.5 justify-between">
                      <div className="flex items-center gap-x-2.5">
                        <img
                          src={reply?.user?.avatar}
                          className="w-6 h-6 border rounded-full"
                          alt=""
                        />
                        <p className="font-medium">{reply?.user?.full_name}</p>
                      </div>
                      <p className="text-xs text-gray-500">
                        {formatDate(reply?.created_at, DATE_TIME_FORMAT)}
                      </p>
                    </div>
                    <div>{reply?.content}</div>
                  </div>
                ))}
              </div>
              <div>
                <Collapse
                  activeKey={activeKeys}
                  containerClassName="flex flex-col gap-1 w-full"
                  headingClassName={
                    'p-1 bg-transparent !rounded-e-full focus:ring-0 flex flex-row-reverse gap-2.5 border-none border-b-[1px] border-gray-500'
                  }
                  contentClassName={'border-none p-0 !text-red-500'}
                  onChange={(key: any) => setActiveKeys(key)}
                  items={listCollapse()}
                />
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default observer(StudentEvaluate);
