import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { DATE_FORMAT } from '_common/constants/common';
import classNames from 'classnames';
import moment from 'moment';
import { useState } from 'react';
import { Spin } from 'tera-dls';
import DashboardApi from '../api';
import Header from '../Header';
import ModalChart from './ModalChart';

function TopCourse(props) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { type = 'small', wrapperClassName, onClose } = props;
  const isSmallType = type === 'small';
  const startOfMonth = moment().startOf('month').format(DATE_FORMAT);
  const endOfMonth = moment().endOf('month').format(DATE_FORMAT);

  const { data, isLoading } = useQuery(
    ['get-top-course-list'],
    () => DashboardApi.getTopCourse(),
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  return (
    <Spin spinning={isLoading}>
      <Header
        isSmallType={isSmallType}
        title="Top 10 khóa học"
        isRefetch={false}
        isSetting={false}
        onZoomIn={onClose}
        onZoomOut={() => setOpenModal(true)}
      />
      <span className="text-[10px] text-[#6B7280]">
        {startOfMonth} - {endOfMonth}
      </span>
      <div
        className={classNames(
          'mt-2.5 overflow-auto pr-2.5 max-h-[300px]',
          wrapperClassName,
        )}
      >
        {data?.length > 0 ? (
          <>
            {data?.map((item, index) => (
              <div
                key={index}
                className="flex justify-between items-center gap-x-5 pt-2.5 pb-4 [&:not(:last-child)]:border-b"
              >
                <p className="flex gap-x-2.5">
                  <span className="text-[10px] inline-block">{index + 1}.</span>{' '}
                  <span className="text-blue-600 font-medium break-word line-clamp-1">
                    {item.name}
                  </span>
                </p>
              </div>
            ))}
          </>
        ) : (
          <NoData />
        )}
      </div>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
        />
      )}
    </Spin>
  );
}

export default TopCourse;
