import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ButtonCommon from '_common/component/Button';
import CkEditor, {
  convertClassNameToInlineStyle,
} from '_common/component/CkEditor';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import {
  DATE_BACKEND_FORMAT,
  MAXIMUM_CURRENCY,
  REGEX,
} from '_common/constants/common';
import { messageValidate, messageWarning } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import RangePicker from '_common/dof/Control/RangePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectProductCategory from '_common/dof/Select/SelectProductCategory';
import { usePrompt } from '_common/hooks/usePrompt';
import moment from 'moment';
import { ReactNode, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  BookmarkOutlined,
  Breadcrumb,
  notification,
  Row,
  Spin,
} from 'tera-dls';
import ProductApi from '../../api';
import { UNIT_TYPE } from '../../constants';
import { PRODUCT_URL } from '../../url';
import ListImageProduct from './ListImage';
import CheckBox from '_common/dof/Control/CheckBox';

const formItemClasses = 'mb-0';

export const CardContent = (props: {
  title: ReactNode;
  children?: ReactNode;
}) => {
  return (
    <div>
      <h4 className="text-blue-500 font-medium mb-5 w-full">{props.title}</h4>
      {props.children}
    </div>
  );
};

const ProductForm = () => {
  const navigate = useNavigate();
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      description: null,
      unit_price: 0,
      status: true,
      category_id: null,
    },
  });
  const {
    formState: { isDirty },
  } = form;
  const { id } = useParams();
  const queryClient = useQueryClient();

  const description = form.watch('description');

  usePrompt(
    <p className="break-word">
      <p>{messageWarning.WARNING_EXIT_1}</p>
      <p>{messageWarning.WARNING_EXIT_2}</p>
    </p>,
    isDirty,
  );

  const {
    data: detail,
    refetch: fetchData,
    isLoading,
  } = useQuery(['get-product-detail', id], () => ProductApi.getDetail(id), {
    staleTime: 300000,
    cacheTime: 300000,
    enabled: !!id,
  });

  useEffect(() => {
    id && fetchData();
  }, [id]);

  useEffect(() => {
    if (detail) {
      form.reset(
        {
          ...detail,
          thumbnail: detail?.image_url,
          have_serial: Boolean(detail?.have_serial ?? 0),
          ...(detail?.promotional_time?.[0] && {
            promotional_time: [
              moment(detail?.promotional_time?.[0]),
              moment(detail?.promotional_time?.[1]),
            ],
          }),
          status: detail?.status == 'active' ? true : false,
        },
        { keepDirty: false },
      );
    } else {
      // form.setValue('unit_price', 0);
      // form.setValue('status', true);
    }
  }, [detail]);

  const { mutate: mutateAction } = useMutation(
    (variables: any) =>
      id ? ProductApi.update(variables) : ProductApi.create(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-product-list']);
          queryClient.invalidateQueries(['get-product-detail']);
          queryClient.invalidateQueries(['get-product-summary']);
          notification.success({
            message: res?.msg,
          });
          form.reset(form.getValues());
          setTimeout(() => {
            navigate(-1);
          }, 100);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values) => {
    mutateAction({
      params: {
        ...values,
        have_serial: Number(values?.have_serial ?? false),
        ...(values?.promotional_time?.[0] && {
          promotional_time: [
            values?.promotional_time?.[0]?.format(DATE_BACKEND_FORMAT),
            values?.promotional_time?.[1]?.format(DATE_BACKEND_FORMAT),
          ],
        }),
        description: convertClassNameToInlineStyle(
          (window as any).editor ? (window as any).editor.getData() : null,
        ),
        status: values?.status ? 'active' : 'inactive',
      },
      ...(id && { id }),
    });
  };

  const unitOptions = Object.entries(UNIT_TYPE).map(([value, label]) => ({
    value,
    label,
  }));

  return (
    <div className="tera-page-form !gap-0">
      <div className="page-header-sticky bg-[#FAFAF9]">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <ArrowSmallLeftSolid className="size-6" />
            </div>
            <Breadcrumb
              separator={'/'}
              containerItemClassName="text-sm"
              items={[
                {
                  title: (
                    <a className="cursor-pointer text-blue-400 italic ">
                      Danh sách sản phẩm
                    </a>
                  ),
                  onClick: () => navigate(PRODUCT_URL.list.path),
                },
                {
                  title: (
                    <a className="font-medium hover:text-gray-700 cursor-default tracking-[0.2px]">
                      {id ? 'Sửa sản phẩm' : 'Thêm sản phẩm'}
                    </a>
                  ),
                },
              ]}
            />
          </div>
          <div className="page-header-v2__function">
            <ButtonCommon
              htmlType="submit"
              type="success"
              className="page-header-btn bg-green-500"
              icon={<BookmarkOutlined className="w-4" />}
              onClick={form.handleSubmit(handleSubmitForm)}
            >
              Lưu
            </ButtonCommon>
          </div>
        </div>
      </div>
      <div className="page-content-v2 bg-white shadow-xsm !rounded-xl px-2.5 h-full mx-2.5 mb-2.5 !gap-y-0">
        <Spin spinning={!!id && isLoading}>
          <FormTera form={form}>
            <div className="space-y-4">
              <CardContent title="Thông tin sản phẩm">
                <Row className={'grid-cols-2 xl:grid-cols-3 gap-x-3'}>
                  <FormTeraItem
                    label="Tên sản phẩm"
                    name="name"
                    rules={[{ required: messageValidate.emptyText }]}
                    className={formItemClasses}
                  >
                    <Input maxLength={100} />
                  </FormTeraItem>
                  <FormTeraItem
                    label="SKU"
                    name="sku"
                    className={formItemClasses}
                    rules={[
                      {
                        required: messageValidate.emptyText,
                        pattern: {
                          value: REGEX.SKU,
                          message: 'SKU không hợp lệ',
                        },
                      },
                    ]}
                  >
                    <Input max={255} />
                  </FormTeraItem>
                  <FormTeraItem
                    label="Mã vạch / Barcode"
                    name="barcode"
                    className={formItemClasses}
                  >
                    <Input maxLength={255} />
                  </FormTeraItem>
                </Row>
                <FormTeraItem name="status" label="" className="w-full mt-3">
                  <CheckBox labelClassName="font-normal text-[13px]">
                    Hoạt động
                  </CheckBox>
                </FormTeraItem>
              </CardContent>
              <CardContent title="Phân loại">
                <Row className={'grid-cols-2 xl:grid-cols-3 gap-x-3'}>
                  <FormTeraItem
                    label="Đơn vị"
                    name="unit_key"
                    className={formItemClasses}
                  >
                    <Select options={unitOptions} />
                  </FormTeraItem>

                  <FormTeraItem
                    label="Danh mục"
                    name="category_id"
                    className={formItemClasses}
                    rules={[
                      {
                        required: messageValidate.emptySelect,
                      },
                    ]}
                  >
                    <SelectProductCategory
                      paramsApi={{ include_id: form.watch('category_id') }}
                    />
                  </FormTeraItem>
                </Row>
              </CardContent>
              <CardContent title="Giá">
                <Row className={'grid-cols-2 xl:grid-cols-3 gap-x-3'}>
                  <FormTeraItem
                    label="Giá bán"
                    name="unit_price"
                    className={formItemClasses}
                    rules={[
                      {
                        required: messageValidate.emptyText,
                      },
                    ]}
                  >
                    <InputNumber min={0} max={MAXIMUM_CURRENCY} />
                  </FormTeraItem>

                  <FormTeraItem
                    label="Giá khuyến mãi"
                    name="promotional_price"
                    className={formItemClasses}
                  >
                    <InputNumber
                      defaultValue={0}
                      min={0}
                      max={MAXIMUM_CURRENCY}
                    />
                  </FormTeraItem>
                  <FormTeraItem
                    label="Thời gian khuyến mãi"
                    name="promotional_time"
                    className={formItemClasses}
                  >
                    <RangePicker
                      minDate={moment()}
                      placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                    />
                  </FormTeraItem>
                </Row>
              </CardContent>
              <CardContent title="Mô tả">
                <FormTeraItem>
                  <div className="tailwind-preflight">
                    <CkEditor
                      name="description"
                      data={description}
                      editorCallback={(editor) =>
                        editor.editing.view.change((writer) => {
                          writer.setStyle(
                            'height',
                            '300px',
                            editor.editing.view.document.getRoot(),
                          );
                        })
                      }
                    />
                  </div>
                </FormTeraItem>
              </CardContent>
              <CardContent title="Ảnh sản phẩm">
                <ListImageProduct
                  defaultProductImages={detail?.product_images}
                />
              </CardContent>
            </div>
          </FormTera>
        </Spin>
      </div>
    </div>
  );
};

export default ProductForm;
